// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 212px);
}
.search-loader .dotLoader {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/views/Search/styles.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,2BAAA;AACJ;AAAI;EACE,kBAAA;AAEN","sourcesContent":[".search-loader {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: calc(100vh - 212px);\n    .dotLoader {\n      position: relative;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
