import React from "react";
import Info from "../../components/svgicons/infoIcon";
import Successfully from "../../images/succesfully.svg";
import ThankYouGIF from "../../images/thankyou.gif";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import CrossIcon from "../../components/svgicons/Cross-icon";
import "./styles.scss";

function ThankYouModal({
  openModal,
  setOpenModal,
  orderAmount,
  orderPaymentMode,
  orderDetails,
  openErrorModal,
  setOpenErrorModal
}) {
  const navigate = useNavigate();

  return (
    <>
      <Modal
        isOpen={openModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thankyouModal"
      >
        <ModalHeader>
          <h1 className="d-flex align-items-center justify-content-between">
            Order Placed Successfully
          </h1>
          <div className="thankyou-modal-close"  onClick={() => {
                setOpenModal(false);
                localStorage.removeItem("cart");
                navigate(localStorage.getItem("slug"));
                navigate("/buyer-order-details", {
                  state: { orderId: orderDetails?._id },
                });
              }}>
          <CrossIcon />
          </div>

        </ModalHeader>
        <ModalBody>
          <div className="thankyougif">
            <img src={ThankYouGIF} alt="Thank You"></img>
          </div>
          <p>
            {orderPaymentMode === "KikoPayment"
              ? "Total Amount Paid"
              : "Total Order Amount"}{" "}
            <span>₹{orderAmount}</span>
          </p>
          <h1>
            <img src={Successfully} className="successIcon" alt="thankyou" />
            Thank You!
          </h1>
          <p>
            {orderPaymentMode === "KikoPayment"
              ? "Payment Done Successfully"
              : ""}
          </p>
          <div className="d-flex justify-content-center">
            <button
              className="trackOrder"
              onClick={() => {
                setOpenModal(true);
                localStorage.removeItem("cart");
                navigate(localStorage.getItem("slug"));
              }}
            >
              Explore More
            </button>
            <button
              className="trackOrder"
              onClick={() => {
                setOpenModal(false);
                localStorage.removeItem("cart");
                navigate(localStorage.getItem("slug"));
                navigate("/buyer-order-details", {
                  state: { orderId: orderDetails?._id },
                });
              }}
            >
              Track Your Order
            </button>
          </div>
          <div style={{ height: "0px" }}></div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={openErrorModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="thankyouModal oops-modal">
          
          <ModalHeader>
         <div>
         <h1>
            <Info className="icon" />
            Oops!!
            <br /> <br />
            Somthing Went Wrong !
            <br /> <br />
            Your money will be refunded back in 2-3 business days.
          </h1>
          <button
            className="trackOrder"
            onClick={() => {
              setOpenErrorModal(false);
              localStorage.removeItem("cart");
              navigate(localStorage.getItem("slug"));
            }}
          >
            Explore More
          </button>
         </div>

        </ModalHeader>
        <div style={{ height: "0px" }}></div>
      </Modal>
    </>
  );
}

export default ThankYouModal;
