
import { createSlice ,createAsyncThunk } from '@reduxjs/toolkit';
import {fetchSellersData} from "../api/ApiService";
export const initialState = {
  loading: false,
  hasErrors: false,
  sellersStatus: "",
  sellers:[],
  data: {},
};

export const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSellers.pending, (state) => {
        state.loading = true;
        state.hasErrors = false;
        state.sellersStatus = "loading"
      })
      .addCase(fetchSellers.fulfilled, (state, action) => {
        state.sellers = action?.payload?.sellers || []
        state.data = action.payload;
        state.sellersStatus = "loaded"
        state.hasErrors = false;
      })
      .addCase(fetchSellers.rejected, (state) => {
        state.hasErrors = true;
        state.sellersStatus = "loaded"
      })
  },
});


export const fetchSellers = createAsyncThunk(
  'seller/fetchSellers',
  async (body) => {
    const data =  fetchSellersData(body); 
    return data
     
  }
);

export default sellerSlice.reducer;

