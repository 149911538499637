import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/component";
import "./styles.scss";
import { ondcSelect, ondcInit, ondcConfirm } from "../../slices/ondcPreorderSlice";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import locationIcon from "../../images/location-icon.svg";
import { Modal } from "reactstrap";
import { MinusIcon, PlusIcon } from "../../components/svgicons";
import API from "../../api/indexv2";
import { handleError, errorCodeMessages } from "../../components/common/utils";
import { GET_USER_ADDRESS } from "../../api/apiList";
import { updateCartItem } from "../../slices/catalogSlice";
import { USER_DATA } from "../../components/common/constant";
import Loader from "../../components/common/loader";
import debounce from "lodash/debounce";
import WrappedMap from "../../components/Map/map";
import store from "../../store";
import blacksearchIcon from "../../images/blacksearchicon.svg";
import { getLocations, startLoadingMap } from "../../slices/mapSlice";
import EmptyCart from "../../images/cart.png";
import RightIcon from "../../images/ProductImg/success-right.svg";
import PreRazorpay from "../Razorpay/PreRazorpay";
import ThankYouModal from "../../components/common/ThankyouModal";
import {
  getPlacesDetails,
  placeDetail,
  handleAddress,
} from "../../api/ApiService";
import searchIcon from "../../images/searchIcon.svg";
import { notify } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";

const Checkout = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [isNewBillingAddress, setIsNewBillingAddress] = useState(false);
  const navigate = useNavigate();
  let cartItems = JSON.parse(localStorage.getItem("cart"));
  const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
  const user = JSON.parse(localStorage.getItem("user"));
  const ondcCartDataLocal = JSON.parse(localStorage.getItem("ondcCart"));
  const sellerDetails = JSON.parse(localStorage.getItem("sellerDetails"));
  const [cartItemsTemp, setCartItemsTemp] = useState(cartItems);
  const [ondcCartData, setOndcCartData] = useState(ondcCartDataLocal);
  const [loading, setLoading] = useState(false);
  const [openRazorpay, setOpenRazorpay] = useState(false);
  const [disableUpdateCart, setDisableUpdateCart] = useState(true);
  const [payableAmount, setPayableAmount] = useState(0);
  const [orderDetails, setOrderDetails] = useState({});
  const [editAddress, setEditAddress] = useState({});
  const [getCurrentLocationLoader, setGetCurrentLocationLoader] = useState(false);
  const [userAddress, setUserAddress] = useState([]);
  const [map, setMap] = useState(false);
  const [tempAddress, setTempAddress] = useState({});
  const [openFromEdit, setOpenFromEdit] = useState(false);
  const [loadingAddAddress, setLoadingAddAddress] = useState(false);
  const [building, setLandmark] = useState("");
  const getUserFromLocalStorage = () => {
    try {
      return JSON.parse(localStorage.getItem(USER_DATA) || "");
    } catch (error) {
      return null;
    }
  };
  const userData = getUserFromLocalStorage();
  const [emailId, setEmailId] = useState(userData ? userData.email : "");
  const [buyerName, setBuyerName] = useState(userData ? userData.name : "");
  const [searchmodal, setSearchModal] = useState(false);
  const [hideCartEdit, setHideCartEdit] = useState(true);
  const [showAddressEdit, setShowAddressEdit] = useState(false);
  const [buyerNumber, setBuyerNumber] = useState(
    userData ? userData.mobile : ""
  );
  const [openThankPopup, setOpenThankPopup] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [shippingAddress, setShippingAddress] = useState(defaultAddress?.address);
  const [billingAddress, setBillingAddress] = useState(shippingAddress);
  const [accordionSteps, setAccordionSteps] = useState([
    { id: 1, title: 'Cart', completed: false },
  ]);
  const ondcIsCartError = useSelector((s) => s.ondcPreorder.ondcIsCartError);
  const ondcConfirmstatus = useSelector((s) => s.ondcPreorder.ondcConfirmstatus);
  const locations = useSelector((s) => s.mapApi.locations);
  const loadingMapAddress = useSelector((s) => s.mapApi.startLoadingMap);
  const address = useSelector((s) => s.mapApi.address);
  const ondcInitstatus = useSelector((s) => s.ondcPreorder.ondcInitstatus);
  const [ondcOrderId, setOndcOrderId] = useState('');
  const [deliveryType, setDeliveryType] = useState("")
  const [deliveryTypeFulfillmentId, setDeliveryTypeFulfillmentId] = useState("")

  useEffect(() => {
    setTempAddress({ ...address, tag: "HOME" });
    setEditAddress({
      ...editAddress, ...address,
      tag: "HOME",
      region: {
        latitude: address?.latitude,
        longitude: address?.longitude,
      },
    });
    if (address?.latitude && searchmodal) {
      setMap(true);
      setSearchModal(false);
    }
  }, [address]);

  useEffect(() => {
    fetchAddress();
  }, []);

  useEffect(() => {
    const deliveryTypeObj = ondcCartData?.message?.order?.fulfillments?.find((item) => item.type === "Delivery")
    if (deliveryTypeObj && !deliveryType) {
      setDeliveryTypeFulfillmentId(deliveryTypeObj?.id)
      setDeliveryType(deliveryTypeObj.type)
    }
  }, [ondcCartData]);

  const checkValidation = (value) => {
    if (!value || value === undefined || value == null || value === "") {
      return false;
    } else {
      return true;
    }
  };

  const checkEmailPhoneValidation = (type, value) => {
    if (type === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(value);
    } else if (type === "phone") {
      const phoneRegex = /^\d{10}$/;
      return phoneRegex.test(value);
    } else {
      return true;
    }
  };

  const dispatchOndcSelect = () => {
    setLoading(true);
    const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
    const select_transaction_id = JSON.parse(localStorage.getItem("transaction_id"));
    const items = cartItemsTemp.map((item) => ({
      id: item.catalogData._id,
      quantity: item.quantity,
    }));
    const transaction_id = select_transaction_id ? select_transaction_id : ""
    dispatch(ondcSelect({
      items,
      userAddress: defaultAddress?.address,
      userId: user?._id,
      transactionId: transaction_id
    }))
      .then((response) => {
        setOndcCartData(response?.payload?.data);
        if (response?.payload?.data &&
          response?.payload?.success &&
          !response?.payload?.data?.error) {
          setError("")
          localStorage.setItem(
            "ondcCart",
            JSON.stringify(response?.payload?.data)
          );
          setLoading(false);
          setDisableUpdateCart(true);
        }
        else {
          const error = errorCodeMessages(
            response?.payload?.data?.error?.code,
            response?.payload?.data?.error?.message
          );
          setError(error)
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error dispatching ondcCart:", error);
      });
  };

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
    const itemIndex = cartItemsTemp.findIndex((item) => item._id === itemId);
    if (newQuantity > parseInt(singleCatalogData.availableQuantity)) {
      notify("success", "Available Quantity is only " + singleCatalogData?.availableQuantity)
    } else {
      if (itemIndex !== -1) {
        let updatedCartItems = [...cartItemsTemp];
        if (newQuantity === 0) {
          const arr = updatedCartItems.filter(function (item) {
            return item !== updatedCartItems[itemIndex];
          });
          updatedCartItems = arr;
        } else {
          updatedCartItems = cartItems.map((item, index) =>
            index === itemIndex ? { ...item, quantity: newQuantity } : item
          );
        }
        setCartItemsTemp(updatedCartItems);
        localStorage.setItem("cart", JSON.stringify(updatedCartItems));
        dispatch(updateCartItem(updatedCartItems));
        setDisableUpdateCart(false);
      }
    }
  };

  const handleUpdateCartClicked = () => {
    dispatchOndcSelect();
  };

  const handleDeliveryTypeSelection = (data, type) => {
    const deliveryTypeFulfillment = data?.message?.order?.fulfillments.find(item => item.type === type);
    setDeliveryType(type)
    if (deliveryTypeFulfillment?.id) {
      setDeliveryTypeFulfillmentId(deliveryTypeFulfillment?.id)
    }
  }

  const proeedToBuy = (orderId) => {
    if (orderId && orderId !== '' && user?._id) {
      setOpenRazorpay(true)
    }
    else {
      setError("Somthing Went Wrong ! Invalid Order Id")
    }
  };


  const handleBillingAddressSelection = () => {
    setBillingAddress(shippingAddress);
  };

  const handleShippingAddressSelection = (data) => {
    setShippingAddress(data);
    ///Set Shipping Address///
    const defaultAddress = {
      latitude: data?.region?.latitude,
      longitude: data?.region?.longitude,
      zipcode: data?.zipcode,
      address: data
    }
    localStorage.setItem('defaultAddress', JSON.stringify(defaultAddress))
    dispatchOndcSelect();
  };

  const handleNewBillingAddressSelection = () => {
    setSearchModal(true);
    setOpenFromEdit();
    setIsNewBillingAddress(true);
  };
  useEffect(() => {
    setBillingAddress(shippingAddress);
  }, [shippingAddress]);

  const fetchAddress = () => {
    API.get(GET_USER_ADDRESS, { params: { userId: get(user, "_id", "") } })
      .then(({ data }) => {
        if (data) {
          setUserAddress(data.addresses);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };


  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      setGetCurrentLocationLoader(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          if (openFromEdit) {
            setEditAddress({
              ...editAddress,
              region: {
                latitude: latitude,
                longitude: longitude,
              },
            });
          }
          else {
            setTempAddress({
              ...tempAddress,
              latitude: latitude,
              longitude: longitude,
            });
          }
          const obj = { latitude, longitude };
          getPlacesDetails(obj);
          setOpenFromEdit(false);
          setMap(true);
          setSearchModal(false);
          setGetCurrentLocationLoader(false);
        },
        (error) => {
          setGetCurrentLocationLoader(false);
          notify("error", "Error in facting your current location Try Again!");
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const updateAddress = () => {
    setLoadingAddAddress(true);

    const addressId = editAddress.addressId;
    if (!addressId) {
      console.error("Address ID is missing in editAddress:", editAddress);
      notify("error", "Address ID is missing in editAddress.");
      setLoadingAddAddress(false);
      return;
    }

    const region = {
      latitude: parseFloat(
        editAddress.latitude
          ? editAddress.latitude
          : editAddress.region?.latitude
      ),
      longitude: parseFloat(
        editAddress.longitude
          ? editAddress.longitude
          : editAddress.region?.longitude
      ),
    };
    if ((buyerName + editAddress?.building + editAddress?.address_line2)?.length > 190) {
      notify("warning", "Max Character limit exceeded, Please write short address");
      return;
    }

    const updatedBody = {
      addressId: addressId,
      contactName: buyerName,
      contactPhone: `+91${buyerNumber}`,
      mobile: buyerNumber,
      city: editAddress?.city,
      state: editAddress?.state,
      zipcode: editAddress?.pincode ? editAddress?.pincode : editAddress?.zipcode,
      address_line1: editAddress?.building ? editAddress?.building : "",
      address_line2: editAddress?.address_line2,
      building: editAddress?.building ?? "",
      status: editAddress?.status,
      tag: editAddress?.tag,
      region: region,
      emailId: emailId,
    };

    API.post("/edit-address", updatedBody)
      .then((res) => {
        const data = res?.data;
        if (data?.success) {
          fetchAddress();
        } else {
          console.error(data.message);
          notify(
            "error",
            "Error occurred while updating address. Please try again."
          );
        }
        setLoadingAddAddress(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        notify(
          "error",
          "An error occurred while updating address. Please try again."
        );
        setLoadingAddAddress(false);
      });
  };

  const handleGetCurrentLocations = () => {
    const { region } = tempAddress;
    if (
      region &&
      region.latitude !== undefined &&
      region.longitude !== undefined
    ) {
      const latitude = Number(region.latitude);
      const longitude = Number(region.longitude);
      setMap(true);
      setSearchModal(false);
      getPlacesDetails({ latitude, longitude });
    } else {
      console.error(
        "Latitude or longitude is not available in the saved address."
      );
    }
  };

  const onChangePincodeDetail = (e) => {
    if (e.target.value !== "" && e.target.value.length >= 3) {
      handleAddress(e.target.value);
    } else {
      store.dispatch(startLoadingMap(false));
      store.dispatch(getLocations([]));
    }
  };
  const debouncedOnChange = debounce(onChangePincodeDetail, 500);

  const handleEditButtonClick = (data) => {
    const addressId = data.addressId || data._id || "";
    setTempAddress({})
    setEditAddress({
      ...data,
      addressId: addressId,
    });
    setOpenFromEdit(true);
    setMap(true);
    setBuyerName(data.contactName || "");
    setBuyerNumber(data.mobile || "");
    setEmailId(data.emailId || "");
    setLandmark(data.building || "");

    if (
      data.region &&
      data.region.latitude !== undefined &&
      data.region.longitude !== undefined
    ) {
      handleGetCurrentLocations();
    }
  };

  const saveAddress = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (userAddress && userAddress.length > 0) {
      const defaultAddress = {
        latitude: userAddress[0]?.region?.latitude,
        longitude: userAddress[0]?.region?.longitude,
        zipcode: userAddress[0]?.zipcode,
        address: userAddress[0],
      };
      localStorage.setItem("defaultAddress", JSON.stringify(defaultAddress));
    }
    const isTagValid = checkValidation(tempAddress?.tag);
    const isBuyerEmailValid = checkValidation(emailId);
    const isPhoneNumberValid = checkValidation(buyerNumber);
    const isPinCodeValid = checkValidation(
      tempAddress?.pincode ? tempAddress?.pincode : tempAddress?.zipcode
    );
    const isLandmarkValid = checkValidation(building);
    const isStateValid = checkValidation(tempAddress?.state);
    const isAddressLine2Valid = checkValidation(tempAddress?.address_line2);
    const isCityValid = checkValidation(tempAddress?.city);
    const isLatitudeValid = checkValidation(
      tempAddress.latitude ? tempAddress?.latitude : tempAddress?.region?.latitude
    );
    const isLongitudeValid = checkValidation(
      tempAddress?.longitude
        ? tempAddress?.longitude
        : tempAddress?.region?.longitude
    );
    if (
      !isTagValid ||
      !isPhoneNumberValid ||
      !isPinCodeValid ||
      !isLandmarkValid ||
      !isStateValid ||
      !isAddressLine2Valid ||
      !isCityValid
    ) {
      notify("warning", "Please enter valid details");
    } else if (
      !openFromEdit &&
      (!isBuyerEmailValid ||
        !isPhoneNumberValid ||
        !isPinCodeValid ||
        !isLandmarkValid ||
        !isStateValid ||
        !isAddressLine2Valid ||
        !isCityValid ||
        !isLatitudeValid ||
        !isLongitudeValid)
    ) {
      notify("warning", "Please enter valid details");
    } else {
      setLoadingAddAddress(true);
      const region = {
        latitude: parseFloat(
          tempAddress.latitude
            ? tempAddress.latitude
            : tempAddress.region?.latitude
        ),
        longitude: parseFloat(
          tempAddress.longitude
            ? tempAddress.longitude
            : tempAddress.region?.longitude
        ),
      };
      if ((buyerName + building + tempAddress?.address_line2)?.length > 190) {
        notify("warning", "Max Character limit exceeded, Please write short address");
        return;
      }

      const body = {
        contactName: buyerName,
        contactPhone: `+91${buyerNumber}`,
        mobile: buyerNumber,
        city: tempAddress.city,
        state: tempAddress.state,
        zipcode: tempAddress?.pincode
          ? tempAddress.pincode
          : tempAddress.zipcode,
        address_line1: building,
        address_line2: tempAddress.address_line2,
        building: building,
        status: tempAddress.status,
        tag: tempAddress.tag,
        emailId: emailId,
        region: region,
        userId: get(user, "userId", ""),
        uId: get(user, "_id", ""),
      };
      API.post("/create-address", body)
        .then((res) => {
          const data = res?.data
          if (data.success) {
            fetchAddress();

            console.error(data.message);
            notify(
              "error",
              "Error occurred while saving address. Please try again."
            );
          }
          setLoadingAddAddress(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          notify(
            "error",
            "Error occurred while saving address. Please try again."
          );
          setLoadingAddAddress(false);
        });
    }
  };

  const SearchResult = () => {
    const searchLocations = locations.map((item) => (
      <div
        className="resultList"
        onClick={() => {
          setOpenFromEdit(false)
          setEditAddress({})
          setTempAddress({});
          placeDetail(item?.place_id);
        }}
      >
        <img src={blacksearchIcon} alt="Search Icon" />
        <div className="resultText">
          <h4>{item?.structured_formatting?.main_text}</h4>
          <p>{item?.description}</p>
        </div>
      </div>
    ));
    return <>{searchLocations}</>;
  };

  const handlePaymentResponse = (payload) => {
    if (payload?.paymentResponse?.razorpay_payment_id) {
      setLoading(true)
      dispatch(ondcConfirm({
        userId: user?._id,
        transactionFee: payload?.transactionFee,
        razorPayTransactionId: payload?.paymentResponse.razorpay_payment_id
      }))
        .then((response) => {
          setPayableAmount(response?.payload?.data?.payableAmount)
          setLoading(false)
          localStorage.removeItem("cart");
          localStorage.removeItem("transaction_id");
          dispatch(updateCartItem([]));
          if (response?.payload?.success === true) {
            setOrderDetails(response?.payload?.data)
            setOpenThankPopup(true)
          }
          else {
            setOpenErrorModal(true)
          }
        })
        .catch((error) => {
          setLoading(false)
          setError(error)
          console.error("Error dispatching ondc confirm:", error);
        });
    } else {
      setError("Payment Failed Please try again")
    }
  };

  const handleInit = () => {
    const payload =
    {
      userId: user?._id,
      billingAddress: {
        address: {
          name: billingAddress?.contactName,
          building: billingAddress?.building,
          locality: billingAddress?.address_line2,
          city: billingAddress?.city,
          state: billingAddress?.state,
          country: "IND",
          area_code: billingAddress?.zipcode,
        },
        name: billingAddress?.contactName,
        email: billingAddress?.emailId,
        phone: billingAddress?.mobile,
      },
      shippingAddress,
      deliveryType: deliveryType,
      fulfillmentId: deliveryTypeFulfillmentId
    }

    dispatch(ondcInit(payload)).then((res) => {
      setOndcOrderId(res?.payload?.data?.ondcOrderId)
      const data = res?.payload
      if (
        data?.success &&
        !data?.error) {
        setError("")
        if (data?.data?.ondcOrderId) {
          proeedToBuy(data?.data?.ondcOrderId)
        }
      } else {
        setError("Somthing Went Wrong ! Please Try Again");
        setLoading(false);
      }
    });
  };

  const handleSaveAndProceed = () => {
    if (openFromEdit) {
      updateAddress();
      setMap(false)
    } else {
      if (!isNewBillingAddress) {
        saveAddress();
      } else {
        if ((buyerName + building + tempAddress?.address_line2)?.length > 190) {
          notify("warning", "Max Character limit exceeded, Please write short address");
          return;
        }
        const tempBillAddres = {
          contactName: buyerName,
          contactPhone: `+91${buyerNumber}`,
          mobile: buyerNumber,
          city: tempAddress.city,
          state: tempAddress.state,
          zipcode: tempAddress?.pincode
            ? tempAddress.pincode
            : tempAddress.zipcode,
          address_line1: building,
          address_line2: tempAddress.address_line2,
          building: building,
          status: tempAddress.status,
          tag: tempAddress.tag,
          emailId: emailId,
          userId: get(user, "userId", ""),
          uId: get(user, "_id", ""),
        };
        setBillingAddress(tempBillAddres);
        setMap(false);
      }
    }
  };

  const updateStepCompletion = (stepId, isCompleted) => {
    setAccordionSteps(prevSteps =>
      prevSteps.map(step =>
        step.id === stepId ? { ...step, completed: isCompleted } : step
      )
    );
  };

  const uncompleteAllSteps = () => {
    const updatedSteps = accordionSteps.map(step => ({ ...step, completed: false }));
    setAccordionSteps(updatedSteps);
  };

  let totalItemAmount = 0
  let totalOAmount = 0
  let errorData;
  if (ondcCartData?.error?.message) {
    errorData = JSON.parse(ondcCartData?.error?.message);
  }

  const disableCartItemInteraction = () => {
    return loading
  }
  return (
    <><ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"

    />
      <ToastContainer />
      <Header />
      <section className="category-section">
        <div className="container">
          <div className="buyercheckout-heading">
            <div className="row">
              <div className="col-lg-12">
                <div
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                  href="#"
                  className="checkout-title"
                >
                  BACK TO SHOP
                </div>
              </div>
            </div>
          </div>
          {cartItemsTemp && cartItemsTemp.length > 0 ? (
            <div className="cart-details">
              <div className="row">
                <div className="col-lg-8">
                  <div className="accordion buyerCheckoutCollapse" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"

                        >
                          {accordionSteps[0].completed ? <div className="iconCounter activeCounter"> <img src={RightIcon} alt="Empty Cart" /></div> : <div className="iconCounter activeCounter">1</div>}
                          Cart
                        </button>
                        {!hideCartEdit && !loading &&
                          <button
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-controls="collapseOne"
                            aria-expanded="true"
                            className="btn"
                            onClick={() => {
                              setHideCartEdit(!hideCartEdit)
                              uncompleteAllSteps()
                            }}
                          >
                            EDIT
                          </button>
                        }
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div className="buyer-checkout-cart">
                            {cartItemsTemp &&
                              cartItemsTemp?.length > 0 &&
                              cartItemsTemp?.map((cartData) => {
                                return (
                                  <div className="cart_list">
                                    <div className="cart_items">
                                      <div className="d-flex gap-2 align-items-center">
                                        <div className="product__image">
                                          <img
                                            alt="Grocery"
                                            src={
                                              cartData?.catalogData
                                                ?.productImages[0]
                                            }
                                          />
                                        </div>
                                        <div className="product_text">
                                          <div className="ProductTitle"> {cartData?.catalogData?.productName}</div>
                                          <div className="defaultProductPrice">₹ {cartData?.catalogData?.price}</div>
                                          <div className="product-shop-name">
                                            <img
                                              alt="Alpino Health Foods"
                                              src={sellerDetails?.photo}
                                            />
                                            {sellerDetails?.name}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="add--cart-btn">
                                        <div
                                          className="addtocart-qty"
                                          style={{ position: "unset" }}
                                        >
                                          <div
                                            className={`addtocart-button button-down minus${disableCartItemInteraction() && "disabled"}`}
                                            onClick={() => {
                                              !disableCartItemInteraction() &&
                                                updateCartItemQuantity(
                                                  cartData._id,
                                                  cartData.quantity === 1 ? 0 : cartData.quantity - 1,
                                                  cartData.catalogData
                                                );
                                            }}
                                          >
                                            <MinusIcon />
                                          </div>
                                          <input
                                            type="number"
                                            readOnly={true}
                                            className="addtocart-input"
                                            value={cartData.quantity}
                                          />
                                          <div
                                            className={` addtocart-button button-up ${disableCartItemInteraction() && "disabled"}`}
                                            onClick={() => {
                                              !disableCartItemInteraction() &&
                                                updateCartItemQuantity(
                                                  cartData?._id,
                                                  cartData.quantity + 1,
                                                  cartData.catalogData
                                                );
                                            }}
                                          >
                                            <PlusIcon />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="cart-buttons">
                            <button
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                              className="btn btn-sm btn-primary"
                              disabled={loading || ondcIsCartError}
                              onClick={() => {
                                setHideCartEdit(false)
                                updateStepCompletion(1, true);
                              }}
                            >
                              Continue
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                handleUpdateCartClicked();
                              }}
                              disabled={loading || disableUpdateCart}
                            >
                              {loading ? <Loader /> : "Update Cart"}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                        >
                          {accordionSteps[0].completed ? <div className="iconCounter activeCounter"> <img src={RightIcon} alt="Empty Cart" /></div> : <div className={`iconCounter ${accordionSteps[0].completed ? 'activeCounter' : ''}`}>2</div>}
                          Add Address
                        </button>
                        {showAddressEdit &&
                          <button
                            className="btn"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                            onClick={() => {
                              setShowAddressEdit(false)
                            }}
                          >
                            EDIT
                          </button>
                        }
                        {!hideCartEdit && !loading &&
                          <button
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-controls="collapseFour"
                            aria-expanded="true"
                            className="btn"
                            onClick={() => {
                              updateStepCompletion(3, true);
                            }}
                          >
                            EDIT
                          </button>
                        }
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <div>
                            <div>
                              <div></div>
                              {userAddress && userAddress.length > 0 && userAddress.map((data, index) => (
                                <div
                                  key={data?._id}
                                  className="shipping-address-card"
                                >
                                  <p>
                                    <input
                                      type="radio"
                                      id={`Shipping${index}`}
                                      name="shippingAddress"
                                      onChange={() =>
                                        handleShippingAddressSelection(data)
                                      }
                                      checked={data?._id === shippingAddress?._id}

                                    />
                                    <label htmlFor={`Shipping${index}`}></label>
                                  </p>
                                  <div className="right-content">
                                    <div className="shipping-address">
                                      {`${data.contactName}, ${data.address_line2}, Pin Code: ${data.zipcode} `}
                                      <br />
                                      {`phone: ${data.contactPhone}`}
                                    </div>
                                    <div className="edit-icon">
                                      <button
                                        className="btn p-0"
                                        onClick={() => {
                                          setOpenFromEdit(true);
                                          fetchAddress();
                                          setTempAddress(data);
                                          setEditAddress(data);
                                          handleEditButtonClick(data);
                                        }}
                                      >
                                        <svg
                                          width="27"
                                          height="27"
                                          viewBox="0 0 27 27"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <circle
                                            cx="13.5"
                                            cy="13.5"
                                            r="12.5"
                                            fill="white"
                                            stroke="#B9B9B9"
                                            strokeWidth="2" // Changed to camelCase strokeWidth
                                          ></circle>
                                          <path
                                            d="M8.16274 19.9043H8.23745L11.6992 19.5889C12.0784 19.5511 12.4331 19.384 12.7036 19.1157L20.175 11.6444C20.465 11.338 20.6217 10.9292 20.6108 10.5075C20.5999 10.0858 20.4223 9.68564 20.1169 9.39467L17.8423 7.12006C17.5454 6.84121 17.1564 6.68121 16.7492 6.6705C16.3421 6.65978 15.9452 6.7991 15.6341 7.06195L8.16274 14.5333C7.89441 14.8039 7.72733 15.1586 7.68955 15.5378L7.33259 18.9995C7.32141 19.1211 7.33718 19.2436 7.3788 19.3584C7.42041 19.4732 7.48683 19.5774 7.57333 19.6636C7.6509 19.7405 7.7429 19.8014 7.84404 19.8427C7.94518 19.884 8.05349 19.905 8.16274 19.9043ZM16.6884 8.28227L18.9547 10.5486L17.2944 12.1674L15.0696 9.94257L16.6884 8.28227ZM9.30004 15.6789L13.9738 11.0384L16.2152 13.2798L11.5663 17.9286L9.0759 18.161L9.30004 15.6789Z"
                                            fill="#B9B9B9"
                                          ></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <button
                                className="btn new-address-btn"
                                onClick={() => {
                                  setSearchModal(true)
                                  setIsNewBillingAddress(false);
                                  setOpenFromEdit();
                                }
                                }
                              >
                                <svg
                                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium jss132 css-vubbuv"
                                  focusable="false"
                                  aria-hidden="true"
                                  viewBox="0 0 24 24"
                                  data-testid="AddCircleOutlineRoundedIcon"
                                >
                                  <path d="M12 7c-.55 0-1 .45-1 1v3H8c-.55 0-1 .45-1 1s.45 1 1 1h3v3c0 .55.45 1 1 1s1-.45 1-1v-3h3c.55 0 1-.45 1-1s-.45-1-1-1h-3V8c0-.55-.45-1-1-1zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                                </svg>
                                Add new address
                              </button>

                              <div className="add-new-billing">
                                <p>
                                  <input
                                    type="radio"
                                    id="sameAddress"
                                    name="addressType"
                                    onChange={handleBillingAddressSelection}
                                    checked={billingAddress === shippingAddress}
                                  />
                                  <label htmlFor="sameAddress"
                                  >
                                    My billing and shipping address are the same
                                  </label>
                                </p>
                                <p>
                                  <input
                                    type="radio"
                                    id="differentAddress"
                                    onChange={handleNewBillingAddressSelection}
                                    name="addressType"
                                    checked={searchmodal && isNewBillingAddress}
                                  />
                                  <label htmlFor="differentAddress">
                                    Add New Billing Address
                                  </label>
                                </p>
                              </div>

                            </div>
                            <div className="cart-buttons">
                              <button
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                className="btn btn-sm btn-primary"
                                disabled={loading || ondcIsCartError}
                                onClick={() => {
                                  setHideCartEdit(false)
                                  updateStepCompletion(1, true);
                                }}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="billing-shipping-flex">
                        <div>
                          <h4>Billing Address</h4>
                          {billingAddress ? (
                            <div>
                              <h4>{billingAddress?.name}</h4>
                              <div className="billing-address">
                                {`${billingAddress?.contactName}, ${billingAddress?.address_line2}, ${billingAddress?.zipcode}`}
                                <br />
                                {`phone: ${billingAddress?.contactPhone}`}
                              </div>
                            </div>
                          ) : (
                            <p>No billing address selected</p>
                          )}
                        </div>
                        <div>
                          <h4>Shipping Address</h4>
                          {shippingAddress ? (
                            <div>
                              <h4>{shippingAddress.name}</h4>
                              <div className="shipping-address">
                                {`${shippingAddress.contactName}, ${shippingAddress.address_line2}, ${billingAddress?.zipcode}`}
                                <br />
                                {`phone: ${shippingAddress.contactPhone}`}
                              </div>
                            </div>
                          ) : (
                            <p>No shipping address selected</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="summary-card">
                    <div className="summary-heading">
                      <h4 className="summary-title">Summary</h4>
                    </div>
                    {ondcCartData?.message?.order?.quote?.breakup.map((item, index) => {
                      totalItemAmount += (item["@ondc/org/title_type"] === "item") ? parseInt(item?.price?.value) : 0
                      const errorItem = errorData?.find(errorItem => errorItem?.item_id === item["@ondc/org/item_id"]);
                      const errorCode = errorItem?.error;
                      const errorMessage = errorCode ? errorCodeMessages(errorCode) : null;
                      return (
                        item["@ondc/org/title_type"] === "item" &&
                        <>
                          <div key={index} className="summary-content">
                            {errorMessage
                              && (
                                <div className="mb-2" style={{ color: "red" }}>
                                  {errorMessage}
                                  {errorCode === "40002" && (
                                    <div className="d-flex align-items-center gap-3" >
                                      <p className="m-0">Cart Quantity: {cartItems.find(cartItem => cartItem.catalogData.productId === item["@ondc/org/item_id"])?.quantity}</p>
                                      <p className="m-0">Available Quantity: {item?.item?.quantity?.available?.count}</p>
                                    </div>
                                  )}
                                </div>
                              )}
                            {item?.title && <h6>{item?.title}</h6>}
                            <div className="d-flex align-items-center justify-content-between ">
                              {item["@ondc/org/item_quantity"] && (
                                <p className="text-end m-0">
                                  {" "}
                                  {item["@ondc/org/item_quantity"]?.count} * Base
                                  Price
                                </p>
                              )}
                              <p className="m-0">₹{item?.price?.value}</p>
                            </div>
                          </div>
                        </>
                      )
                    })}
                    <div
                      className="summary-flex pt-2 "
                    >
                      <p className="m-0">
                        <b>Total</b>
                      </p>
                      <p className="m-0">
                        <b>₹{totalItemAmount}</b>
                      </p>
                    </div>
                    <div className="summary-content">
                      <p className="m-0">
                        Fulfillment
                      </p>
                      {ondcCartData?.message?.order?.fulfillments
                        .filter((delivery) => delivery.type === deliveryType) // Filter only delivery types
                        .map((delivery, index) => (
                          <div key={index}>
                            <p htmlFor={`deliveryType${index}`}>
                              {delivery["@ondc/org/category"]} -{" "}
                              {delivery?.state?.descriptor?.code}
                            </p>
                          </div>
                        ))}
                      {ondcCartData?.message?.order?.fulfillments
                        .map((delivery, index) => (
                          <div className="summary-flex gap-4">
                            <p>
                              <input
                                type="radio"
                                id={`${delivery?.type}`}
                                onChange={() => handleDeliveryTypeSelection(ondcCartData, delivery?.type)}
                                name={`${delivery?.type}`}
                                checked={deliveryType === delivery?.type}
                              />
                              <label htmlFor={`${delivery?.type}`}>{`${delivery?.type}`}</label>
                            </p>
                          </div>
                        )
                        )}
                    </div>
                    {ondcCartData?.message?.order?.quote?.breakup.map((item, index) => {
                      if (!["item"].includes(item["@ondc/org/title_type"]) && item["@ondc/org/item_id"] === deliveryTypeFulfillmentId) {
                        totalOAmount += parseFloat(item?.price?.value);
                      }
                      return (
                        item["@ondc/org/title_type"] !== "item" && item["@ondc/org/item_id"] === deliveryTypeFulfillmentId &&
                        <>
                          <div key={index} className="summary-content border-0">
                            <div className="d-flex align-items-center justify-content-between">
                              {item?.title && <p className="m-0">{item?.title}</p>}
                              {item["@ondc/org/item_quantity"] && (
                                <p className="text-end ">
                                  {" "}
                                  {item["@ondc/org/item_quantity"]?.count} * Base
                                  Price
                                </p>
                              )}
                              <p className="m-0">₹{item?.price?.value}</p>
                            </div>
                          </div>
                        </>
                      )
                    })}
                    <div className="summary-content border-0 p-0">
                      <div className="summary-flex pt-2"
                        style={{ borderTop: "1px solid #d3d3d3" }}
                      >
                        <p className="m-0">
                          Total Other Charges
                        </p>
                        <p className="m-0">
                          ₹{totalOAmount.toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <div className="summary-footer">
                      {(
                        <div className="summary-flex">
                          <p><b>Order Total</b></p>
                          <p><b>₹{(totalItemAmount + totalOAmount).toFixed(2)}</b></p>
                        </div>
                      )}
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={
                        () => handleInit()
                      }
                      disabled={
                        error ||
                        ondcIsCartError || loading ||
                        ondcConfirmstatus === 'loading' ||
                        !accordionSteps.every(step => step?.completed)
                      }
                    >
                      {loading || ondcInitstatus === "loading" ? <Loader /> : "Proceed To Buy"}
                    </button>
                    {error !== "" &&
                      <p className="error">{error}</p>
                    }
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="no-items">
              <img src={EmptyCart} alt="Empty Cart" />
              <h6>
                Looks like you don't have any <br /> products in your cart
              </h6>
              <p
                onClick={() => navigate("/")}
                type="button"
                style={{ textDecoration: "underline" }}
              >
                Shop now
              </p>
            </div>
          )}
        </div>
      </section>
      <Modal
        isOpen={searchmodal}
        toggle={() => {
          setSearchModal(false);
        }}
        aria-labelledby="contained-modal-title-v"
        ed
        style={{ maxWidth: "600px" }}
        centered
      >
        <div className="addressmodalBlock">
          <h2>Search Location</h2>
          <div className="searchBlock">
            <img src={searchIcon} alt="Search1 Icon" />
            <input
              type="search"
              placeholder="Search for an area, location name"
              onChange={debouncedOnChange}
            />
          </div>
          {loadingMapAddress && <Loader />}
          <div className="currenLocation">
            <img src={locationIcon} alt="Location Icon" />
            {getCurrentLocationLoader ? (
              <Loader />
            ) : (
              <button
                className="AddAddress"
                onClick={() => {
                  handleGetCurrentLocation();
                  setBuyerName()
                  setLandmark()
                  setEmailId()
                }}
              >
                Use Current Location{" "}
              </button>
            )}
          </div>
          <div className={locations.length > 0 && "redultListBlock"}>
            {locations.length > 0 && SearchResult()}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={map}
        aria-labelledby="contained-modal-title-v"
        ed
        toggle={() => {
          setMap(false);
        }}
        className="addressMap-modal"
        centered
      >
        <div className="row">
          <div className="col-6 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Full Name*"
              value={buyerName}
              onChange={(e) => {
                setBuyerName(e.target.value);
              }}
            />
            {checkValidation(buyerName) ? null : (
              <p className="error">This field is required.</p>
            )}
          </div>
          <div className="col-6 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Alternate Number*"
              value={buyerNumber}
              onChange={(e) => {
                setBuyerNumber(e.target.value);
              }}
            />
            {checkEmailPhoneValidation("phone", buyerNumber) ? null : (
              <p className="error">
                Please enter a valid 10-digit phone number.
              </p>
            )}
          </div>
          <div className="col-12 mb-3">
            <input
              type="text"
              className="form-control "
              placeholder="Email Id*"
              value={emailId}
              onChange={(e) => {
                setEmailId(e.target.value);
              }}
            />
            {checkEmailPhoneValidation("email", emailId) ? null : (
              <p className="error">Please enter a valid email address.</p>
            )}
          </div>
          <div className="DropLocationModal">
            <h2>Set Delivery Location</h2>
            <div className="DroMapBlock">
              {
                <WrappedMap
                  currLatlong1={{
                    latitude: openFromEdit ? parseFloat(editAddress?.region?.latitude) : tempAddress.latitude,
                    longitude: openFromEdit ? parseFloat(editAddress?.region?.longitude) : tempAddress.longitude,
                  }}
                  func={(obj) => {
                    getPlacesDetails(obj);
                  }}
                />
              }
            </div>
          </div>
          <div className="col-6 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Building Name*"
              value={building}
              onChange={(e) => {
                setLandmark(e.target.value);
              }}
            />
            {checkValidation(building) ? null : (
              <p className="error">This feild is required.</p>
            )}
          </div>
          <div className="col-6 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Pincode*"
              value={
                (openFromEdit ? editAddress?.pincode ? editAddress.pincode : editAddress.zipcode : tempAddress?.pincode ? tempAddress.pincode : tempAddress.zipcode)
              }
              onChange={(e) => {
                setTempAddress({ ...tempAddress, pincode: e.target.value });
              }}
            />
            {checkValidation(
              openFromEdit ? editAddress?.pincode ? editAddress.pincode : editAddress.zipcode : tempAddress?.pincode ? tempAddress.pincode : tempAddress.zipcode) ? null : (
              <p className="error">This feild is required.</p>
            )}
          </div>
          <div className="col-6 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="City*"
              value={tempAddress?.city || editAddress?.city}
              onChange={(e) => {
                setTempAddress({ ...tempAddress, city: e.target.value });
                setEditAddress({ ...tempAddress, city: e.target.value });
              }}
            />
            {checkValidation(tempAddress?.city || editAddress?.city) ? null : (
              <p className="error">This feild is required.</p>
            )}
          </div>
          <div className="col-12 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="State*"
              value={tempAddress?.state || editAddress?.state}
              onChange={(e) => {
                setTempAddress({ ...tempAddress, state: e.target.value });
                setEditAddress({ ...tempAddress, state: e.target.value });
              }}
            />
            {checkValidation(
              tempAddress?.state || editAddress?.state
            ) ? null : (
              <p className="error">This feild is required.</p>
            )}
          </div>
          <div className="col-12 mb-3">
          </div>
          <div className="col-12 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Area, Colony, Street*"
              value={tempAddress.address_line2 || editAddress.address_line2}
              onChange={(e) => {
                setTempAddress({
                  ...tempAddress,
                  address_line2: e.target.value,
                });
                setEditAddress({ ...tempAddress, address_line2: e.target.value });
              }}
            />
            {checkValidation(tempAddress?.address_line2 || editAddress?.address_line2) ? null : (
              <p className="error">This feild is required.</p>
            )}
          </div>
          <div className="col-12 mb-3">
            <label>Address Type</label>
            <div className="address-type">
              <label>
                Home
                <input
                  type="radio"
                  name="gender"
                  defaultChecked={tempAddress?.tag?.toLowerCase() === "home" || editAddress?.tag?.toLowerCase() === "home"}
                  onChange={(e) => {
                    setTempAddress({ ...tempAddress, tag: "HOME" });
                    setEditAddress({ ...editAddress, tag: "HOME" });
                  }}
                />
              </label>
              <label>
                Work
                <input
                  type="radio"
                  name="gender"
                  defaultChecked={tempAddress?.tag?.toLowerCase() === "work" || editAddress?.tag?.toLowerCase() === "work"}
                  onChange={(e) => {
                    setTempAddress({ ...tempAddress, tag: "WORK" });
                    setEditAddress({ ...editAddress, tag: "WORK" });
                  }}
                />
              </label>
              <label>
                Other
                <input
                  type="radio"
                  name="gender"
                  defaultChecked={tempAddress?.tag?.toLowerCase() === "other" || editAddress?.tag?.toLowerCase() === "other"}
                  onChange={(e) => {
                    setTempAddress({ ...tempAddress, tag: "OTHER" });
                    setEditAddress({ ...editAddress, tag: "OTHER" });
                  }}
                />
              </label>
            </div>
            {checkValidation(tempAddress?.tag || editAddress.tag) ? null : (
              <p className="error">Please Select Tag.</p>
            )}
          </div>
        </div>
        <div className="button-loader mt-3">

          <button
            disabled={!checkValidation(buyerName) || !checkValidation(buyerNumber) || !checkEmailPhoneValidation('email', emailId) || !checkValidation(building)}
            className="btn btn-primary w-100"
            type="button"
            onClick={() => {
              handleSaveAndProceed();
              setMap(false);
            }}
          >
            {openFromEdit ? "UPDATE" : "SAVE & PROCEED"}
          </button>

          <div className="dotLoader">{loadingAddAddress && <Loader />}</div>
        </div>
      </Modal>
      {openRazorpay &&
        <Modal
          isOpen={openRazorpay}
          toggle={() => {
            setOpenRazorpay
              (false);
          }}
        >
          <PreRazorpay
            orderDetails={{ ondcOrderId: ondcOrderId, userId: user?._id }}
            orderAmount={parseFloat(totalItemAmount + totalOAmount)}
            setOpenRazorpay={setOpenRazorpay}
            onPaymentResponse={handlePaymentResponse}
          />
        </Modal>
      }
      {
        loadingMapAddress &&
        <div className="dotLoader">
          {loadingMapAddress && <Loader />}
        </div>
      }
      <ThankYouModal
        orderAmount={payableAmount}
        openModal={openThankPopup}
        setOpenModal={setOpenThankPopup}
        orderDetails={orderDetails}
        openErrorModal={openErrorModal}
        setOpenErrorModal={setOpenErrorModal}
      />
    </>
  );
};
export default Checkout;
