import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/component";
import LeftArrow from "../../components/svgicons/leftArrow";
import { useLocation, useNavigate } from "react-router-dom";
import CartHeader from "../../components/common/CartHeader";
import { Offcanvas } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { notify } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";
import { updateCartItem } from "../../slices/catalogSlice";
import { MinusIcon, PlusIcon } from "../../components/svgicons";
import BrandLogo from "../../images/kikoimg.svg";

const ProductDetails = (props) => {
  const dispatch = useDispatch();
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [quantity, setQuantity] = useState(1);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const cartItems = useSelector((state) => get(state, "catalog.cartItems", []));
  const ondcSelectstatus = useSelector((s) => s.ondcPreorder.ondcSelectstatus);
  const location = useLocation();
  const navigate = useNavigate();
  const { catalogData, catalogDataArray } = location.state;
  const [similarProducts, setSimilarProducts] = useState([]);
  const itemIndex = cartItems?.filter((item) => item._id === catalogData?.variants[0]._id);
  const [singleCatalogData, setSingleCatalogData] = useState(() => {
    setSelectedVariant(catalogData.variants?.[0])
    if (itemIndex && itemIndex.length > 0) {
      setQuantity(itemIndex[0]?.quantity);
      let varientArry = catalogData.variants?.map((v) => {
        const index = cartItems?.findIndex((item) => item?._id === v?._id);
        if (index !== -1) {
          return { ...v, quantity: cartItems[index].quantity };
        } else {
          return { ...v, quantity: 0 };
        }
      })
      return { ...catalogData, variants: varientArry };
    } else return { ...catalogData }
  });

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };
  const closeCart = () => {
    setIsCartOpen(false);
  };

  useEffect(() => {
    if (catalogDataArray && catalogDataArray.length > 0 && singleCatalogData) {
      const similar = catalogDataArray.filter(
        (item) =>
          item.subCategoryId === singleCatalogData.subCategoryId &&
          item._id !== singleCatalogData._id
      );

      const tempArr = similar?.map((data) => {
        let variantArry = data.variants?.map((v) => {
          const index = cartItems?.findIndex((item) => item?._id === v?._id);
          if (index !== -1) {
            return { ...v, quantity: cartItems[index].quantity };
          } else {
            return { ...v, quantity: 1 };
          }
        })
        return { ...data, variants: variantArry };
      })

      const index = cartItems?.findIndex((item) => item?._id === selectedVariant?._id);
      if (index !== -1) {
        setQuantity(cartItems[index].quantity)
      }
      setSimilarProducts(tempArr);
    }
  }, [catalogDataArray, singleCatalogData, cartItems]);

  const regex = /^P(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
  const parseDuration = (duration) => {
    if (duration === 'P0D') {
      return "A Few Seconds"
    }
    const match = duration.match(regex);
    if (!match) return duration;
    const days = parseInt(match[1] || 0, 10);
    const hours = parseInt(match[2] || 0, 10);
    const minutes = parseInt(match[3] || 0, 10);
    const seconds = parseInt(match[4] || 0, 10);
    let formattedDuration = '';
    if (days) formattedDuration += `${days} day${days > 1 ? 's' : ''} `;
    if (hours) formattedDuration += `${hours} hour${hours > 1 ? 's' : ''} `;
    if (minutes) formattedDuration += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    if (seconds) formattedDuration += `${seconds} second${seconds > 1 ? 's' : ''}`;
    return formattedDuration.trim();
  }

  const updateCartItemQuantity = (itemId, newQuantity, singleCatalog, singleVariant) => {
    const itemIndex = cartItems.findIndex((item) => item._id === itemId);
    if (newQuantity > parseInt(singleVariant?.availableQuantity)) {
      notify("success", "Available Quantity is only " + singleVariant?.availableQuantity);
    } else {
      let updatedCartItems;
      if (itemIndex !== -1) {
        // Create a new array with the updated item
        updatedCartItems = cartItems.map((item, index) =>
          index === itemIndex ? { ...item, quantity: newQuantity } : item
        );
        // If the new quantity is 0, remove the item
        if (newQuantity === 0) {
          updatedCartItems = updatedCartItems.filter((item) => item._id !== itemId);
        }
      } else {
        if (newQuantity !== 0) {
          const newCartItem = {
            _id: itemId,
            quantity: newQuantity,
            catalogData: {
              ...singleCatalog, _id: singleVariant?._id, productName: singleVariant?.productName,
              productImages: singleVariant?.productImages, weight: singleVariant?.weight,
              weightUnit: singleVariant?.weightUnit,
              price: singleVariant?.price, description: singleVariant?.description
            },
          };
          updatedCartItems = [...cartItems, newCartItem];
        } else {
          updatedCartItems = [...cartItems];
        }
      }
      localStorage.setItem("cart", JSON.stringify(updatedCartItems));
      dispatch(updateCartItem(updatedCartItems));
    };
  };

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const setVariant = (variant) => {
    if (variant) {
      setSelectedVariant(variant)
      const itemInCart = cartItems.find(item => item?._id === variant?._id);
      setQuantity(itemInCart ? itemInCart?.quantity : 0);
    }
  }
  return (
    <>
      <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Header
        cartItemLength={cartItems?.length}
      />
      <section className="category-section">
        <div className="container">
          <div className="productSection p-0">
            <div className="row justify-content-between">

              <div className="col-lg-5">
                <div className="product-detail-slider">
                  <div className="d-flex gap-5 py-3">
                    <p className="m-0" style={{ cursor: "pointer" }} onClick={() => { navigate(-1); }}>
                      <LeftArrow className="me-2" />
                      Back
                    </p>
                  </div>
                  <Slider
                    slidesToShow={1}
                    slidesToScroll={1}
                    asNavFor={nav2}
                    ref={(slider1) => setNav1(slider1)}
                    arrows={false}
                  >

                    {selectedVariant &&
                      selectedVariant?.productImages?.length > 0
                      ? selectedVariant?.productImages.map((data) => {
                        return (
                          <div className="prodcutImage">
                            <img
                              src={data}
                              alt="Product Catalog"
                              onError={(event) => {
                                event.target.onerror = null; // Prevents potential infinite loop
                                event.target.src = BrandLogo;
                                event.target.classList.add('default-img')
                              }}
                            />
                          </div>
                        );
                      })
                      : null}
                  </Slider>
                  {selectedVariant?.productImages?.length === 1 ? null : (
                    <Slider
                      asNavFor={nav1}
                      ref={(slider2) => setNav2(slider2)}
                      slidesToShow={
                        selectedVariant?.productImages?.length === 2
                          ? 2
                          : selectedVariant?.productImages?.length > 2
                            ? 3
                            : 3
                      }
                      swipeToSlide={true}
                      focusOnSelect={true}
                      arrows={true}
                      className="product-slider-as-nav"
                    >
                      {selectedVariant &&
                        selectedVariant?.productImages?.length > 0
                        ? selectedVariant?.productImages?.map((data) => {
                          return (
                            <div className="prodcutNavImage">
                              <img src={data} alt="Product Catalog" />
                            </div>
                          );
                        })
                        : null}
                    </Slider>
                  )}
                </div>
              </div>
              <div className="col-lg-7">
                <div className="product-Details ">
                  <h3 className="product-details-title">{selectedVariant?.productName}</h3>
                  <div className="d-flex align-items-center gap-2">
                    <span className="productRate">
                      ₹{selectedVariant?.price}
                    </span>
                    {selectedVariant?.maximumPrice &&
                      <span className="line-through">₹{selectedVariant?.maximumPrice}</span>
                    }
                  </div>
                  {selectedVariant?.availableQuantity < 10 &&
                    <label className="filterLabel">
                      <b>Last {selectedVariant?.availableQuantity} left</b> -
                      make it yours!
                    </label>
                  }
                  {singleCatalogData?.variants?.length > 1 && (
                    <>
                      <br />
                      <h6 className="product-desc-title">
                        Available Measure Options
                      </h6>
                      <div className="d-flex align-items-center gap-2 mt-3">
                        {singleCatalogData?.variants?.map((variant) => (
                          <button
                            key={variant?._id}
                            onClick={() => setVariant(variant)}
                            className={selectedVariant?._id === variant?._id ? 'btn btn-primary' : 'btn btn-outline'}> {variant?.weight + " " + variant?.weightUnit}</button>
                        ))}
                      </div>
                    </>
                  )}
                  <div className="addtocart-selector">
                    {cartItems.some(item => item?._id === selectedVariant?._id) ?
                      <div className="addtocart-qty">
                        <div
                          className="addtocart-button button-down minus"
                          onClick={() => {
                            setQuantity(quantity === 1 ? quantity : quantity - 1);
                            const newQuantity = quantity === 1 ? 0 : quantity - 1;
                            updateCartItemQuantity(
                              selectedVariant?._id,
                              newQuantity,
                              singleCatalogData,
                              selectedVariant
                            );
                          }}
                        >
                          <MinusIcon />
                        </div>
                        <input
                          disabled={true}
                          type="text"
                          className="addtocart-input"
                          value={quantity}
                        />
                        <div
                          className="addtocart-button button-up"
                          value={quantity}
                          onClick={() => {
                            setQuantity(
                              quantity ===
                                parseInt(selectedVariant.availableQuantity)
                                ? quantity
                                : quantity + 1
                            );
                            const newQuantity = quantity + 1
                            updateCartItemQuantity(
                              selectedVariant?._id,
                              newQuantity,
                              singleCatalogData,
                              selectedVariant
                            );
                          }}
                        >
                          <PlusIcon />
                        </div>
                      </div>
                      :
                      <div>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setQuantity(1)
                            updateCartItemQuantity(
                              selectedVariant?._id,
                              1,
                              singleCatalogData,
                              selectedVariant
                            );

                          }}
                        >
                          Add
                        </button>
                      </div>
                    }
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        updateCartItemQuantity(
                          selectedVariant?._id,
                          quantity,
                          singleCatalogData,
                          selectedVariant
                        );
                        toggleCart(!isCartOpen);
                      }}
                    >
                      Buy Now
                    </button>
                  </div>
                  <div className="product-text">
                    <h6 className="product-desc-title">Product Description</h6>
                    <p>{selectedVariant?.description}</p>
                    <table className="productDesc-Details">
                      {singleCatalogData?.productId && (
                        <tr>
                          <td>Style code/Product ID</td>
                          <td>{singleCatalogData?.productId}</td>
                        </tr>
                      )}
                      {singleCatalogData && (
                        <tr>
                          <td>Country Of Origin</td>
                          <td>India</td>
                        </tr>
                      )}
                      <tr>
                        <td>Cash On Delivery</td>
                        <td>
                          {"No"}
                        </td>
                      </tr>
                      {(singleCatalogData?.weight || singleCatalogData?.weightUnit) && (
                        <tr>
                          <td>Net Weight</td>
                          <td>
                            {`${selectedVariant?.weightUnit === "unit" && !selectedVariant?.weight ? "1" : selectedVariant?.weight}`}
                            &nbsp;
                            {selectedVariant?.weightUnit}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>Time To Ship </td>
                        <td>
                          {singleCatalogData?.timeToShip ? parseDuration(singleCatalogData?.timeToShip) : 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>Cancellable </td>
                        <td>
                          {singleCatalogData?.isCancellable ? "Yes" : "No"}
                        </td>
                      </tr>

                      <tr>
                        <td>Returnable </td>
                        <td>
                          {singleCatalogData?.isReturnable ? "Yes" : "No"}
                        </td>
                      </tr>
                      {singleCatalogData?.isReturnable && (
                        <tr>
                          <td>Returnable Window</td>
                          <td>{singleCatalogData?.returnWindowTime ? parseDuration(singleCatalogData?.returnWindowTime) : 'N/A'}</td>
                        </tr>
                      )}
                      {singleCatalogData?.packagedFood && (
                        <tr>
                          <td>Packaged Food </td>
                          <td>
                            {singleCatalogData?.packagedFood ? "Yes" : "No"}
                          </td>
                        </tr>
                      )}
                      {singleCatalogData?.nutritionalInfo?.additives_info &&
                        singleCatalogData?.nutritionalInfo?.additives_info !== "NA" &&

                        (
                          <tr>
                            <td>Additives Info </td>
                            <td>{singleCatalogData?.nutritionalInfo?.additives_info}</td>
                          </tr>
                        )}
                      {singleCatalogData?.nutritionalInfo &&
                        singleCatalogData?.nutritionalInfo?.brand_owner_FSSAI_license_no !== "NA" &&
                        (
                          <tr>
                            <td>FSSAI license_no </td>
                            <td>{singleCatalogData?.nutritionalInfo?.brand_owner_FSSAI_license_no}</td>
                          </tr>
                        )}
                      {singleCatalogData?.nutritionalInfo?.nutritional_info &&
                        singleCatalogData?.nutritionalInfo?.nutritional_info !== "NA" &&
                        (
                          <tr>
                            <td>Nutritional_info </td>
                            <td>{singleCatalogData?.nutritionalInfo?.nutritional_info}</td>
                          </tr>
                        )}
                      {singleCatalogData?.packagedFood === true &&
                        singleCatalogData?.statutory_reqs_packaged_commodities &&
                        <>
                          {singleCatalogData?.statutory_reqs_packaged_commodities?.manufacturer_or_packer_name &&
                            singleCatalogData?.statutory_reqs_packaged_commodities?.manufacturer_or_packer_name !== "NA" &&
                            <tr>
                              <td>Packaged Commodities Manufacturer</td>
                              <td>{singleCatalogData?.statutory_reqs_packaged_commodities?.manufacturer_or_packer_name}</td>
                            </tr>
                          }
                          {singleCatalogData?.statutory_reqs_packaged_commodities?.manufacturer_or_packer_address &&
                            singleCatalogData?.statutory_reqs_packaged_commodities?.manufacturer_or_packer_address !== "NA" &&
                            <tr>
                              <td>Packer Address</td>
                              <td>{singleCatalogData?.statutory_reqs_packaged_commodities?.manufacturer_or_packer_address}</td>
                            </tr>
                          }
                          {singleCatalogData?.statutory_reqs_packaged_commodities?.common_or_generic_name_of_commodity &&
                            singleCatalogData?.statutory_reqs_packaged_commodities?.common_or_generic_name_of_commodity !== "NA" &&
                            <tr>
                              <td>Name of Commodity</td>
                              <td>{singleCatalogData?.statutory_reqs_packaged_commodities?.common_or_generic_name_of_commodity}</td>
                            </tr>
                          }
                          {singleCatalogData?.statutory_reqs_packaged_commodities?.month_year_of_manufacture_packing_importsingleCatalogData?.statutory_reqs_packaged_commodities?.month_year_of_manufacture_packing_import &&
                            singleCatalogData?.statutory_reqs_packaged_commodities?.month_year_of_manufacture_packing_import !== "NA" &&
                            <tr>
                              <td>Year of manufacture packing</td>
                              <td>{singleCatalogData?.statutory_reqs_packaged_commodities?.month_year_of_manufacture_packing_import}</td>
                            </tr>
                          }
                        </>
                      }
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 ">
                {similarProducts &&
                  similarProducts?.length > 0 &&
                  (
                    <div className="similar-products-container">
                      <h3 className="Similar-product-title">Similar Products</h3>
                      <div className="ProductList-grid mb-3">
                        {similarProducts &&
                          similarProducts.map((data, index) => {
                            return (
                              <div className="productCard" key={index}>

                                <div
                                  className="store-img"
                                  onClick={() => {
                                    setSingleCatalogData(data);
                                    setSelectedVariant(data?.variants[0])
                                    setQuantity(data?.variants?.[0]?.quantity);
                                    goToTop();
                                  }}
                                >
                                  <img
                                    src={data?.variants?.[0].productImages[0]}
                                    alt="Product Catalog"
                                  />
                                </div>
                                <div className="store-content">
                                  <h6 className="productTitle">
                                    {data?.variants?.[0].productName}
                                  </h6>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>
                                      <span className="purpleText">
                                        ₹{data?.variants?.[0].price}
                                      </span>
                                      {data?.maximumPrice &&
                                        <span className="line-through">₹{data?.variants?.[0].maximumPrice}</span>
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Offcanvas isOpen={isCartOpen} toggle={closeCart} direction="end" backdrop={ondcSelectstatus === "loading" ? "static" : true} className="offcanvas offcanvas-end sidebaroffcanvas">
        <CartHeader
          isCartOpen={isCartOpen}
          closeCart={closeCart}
        />
      </Offcanvas>
    </>
  );
};
export default ProductDetails;
