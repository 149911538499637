import React, { useState, useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./styles.scss";
import crossIcon from "../../images/cross-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { uploadFile, raiseIssuedata } from "../../slices/ondcPostorderSlice";
import { notify } from "../../components/common/utils";

const RaiseIssue = (props) => {
  const orderId = props?.orderId
  const itemData = props?.itemData
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const [subType, setSubType] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedItems, setSelectedItems] = useState("");
  const [errors, setErrors] = useState({
    typeError: "",
    subtypeError: "",
    uploadedImageUrlError: "",
    subjectError: "",
    messageError: "",
    commonError: "",
    selectedItemError: "",
  });

  const { raiseIssuestatus, uploadFilestatus } = useSelector((state) => ({
    uploadFilestatus: get(state, "ondcPostorder.uploadFilestatus", ""),
    raiseIssuestatus: get(state, "ondcPostorder.raiseIssuestatus", ""),
  }));

  const raiseIssue = async () => {
    if (orderId && raiseIssuestatus !== "loading") {
      try {
        const requiredSubTypes = ['ITM02', 'ITM03', 'ITM04', 'ITM05', 'FLM04'];
        const isImageRequired = requiredSubTypes.includes(subType) && (!uploadedImageUrl || uploadedImageUrl.length === 0)
        if (
          !type ||
          !subType ||
          !subject ||
          !message ||
          (isImageRequired && (!uploadedImageUrl || uploadedImageUrl.length === 0))
        ) {
          if (!type) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              typeError: "Please select a type for raise issue.",
            }));
          }
          if (isImageRequired) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              uploadedImageUrlError: "Please Upload Image for this subtype.",
            }));
          } else if (!subType) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              subtypeError: "Please select a subtype for raise issue.",
            }));
          }
          if (!subject) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              subjectError: "Please select a subject for raise issue.",
            }));
          }
          if (!message) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              messageError: "Please select a message for raise issue.",
            }));
          }
          if (selectedItems?.length === 0) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              selectedItemError: "Please Select a product",
            }));
          }
        }
        else {
          const selectedItemData = selectedItems.map((id) =>
            itemData.find((item) => item.id === id)
          );
          if (selectedItemData.length > 0) {
            const selectedItemsDetails = selectedItemData.map(({ id, quantity: { count: quantity } }) => ({
              id,
              quantity,
            }));
            dispatch(
              raiseIssuedata({
                orderId: orderId,
                type,
                SubType: subType,
                subject,
                message,
                imageUrl: uploadedImageUrl,
                selectedItems: selectedItemsDetails,
              })
            ).then((data) => {
              if (data?.payload?.result && data?.payload?.success) {
                props.getOrderDetail();
                props?.setRaiseTicket(false);
                notify("success", "Issue Created");
              } else {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  commonError: "Something went wrong. Please try again later.",
                }));
              }
            });
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              commonError: "Please select an item before raising the issue.",
            }));
          }
        }
      } catch (error) {
        setErrors("Failed to raise issue.");
        console.error("Error raising issue:", error);
      }
    }
  };

  const handleCross = (data) => {
    setUploadedImageUrl((prevState) =>
      prevState.filter((image, index) => index !== parseInt(data))
    );
  };

  const handleFileUpload = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append(`file`, file);
    try {
      dispatch(uploadFile(formData)).then((data) => {
        if (data?.payload && data?.payload?.success) {
          setUploadedImageUrl((prevProductImage) => [
            ...prevProductImage,
            data?.payload?.file_url,
          ]);
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadedImageUrlError: "",
          }));
          notify("success", "Image uploaded successfully!");
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadedImageUrlError:
              "Something went wrong in Uploading Image. Please try again.",
          }));
        }
        fileInputRef.current.value = "";
      });
    } catch (error) {
      console.error("Error uploading image:", error);
      setErrors("Failed to upload image.");
    }
  };

  const handleTypeChange = (e) => {
    setErrors((prevErrors) => ({ ...prevErrors, typeError: "" }));
    setType(e.target.value);
    setSubType("");
  };

  const types = [
    {
      value: "ORD01",
      label: "ORDER",
      subcategories: [
        { value: "ORD01", label: "Order not received" },
        { value: "ORD02", label: "Quality issue" },
        { value: "ORD03", label: "Delayed delivery" },
        { value: "ORD04", label: "Invoice missing" },
        { value: "ORD05", label: "Store not responsive" },
      ],
    },
    {
      value: "ITM01",
      label: "ITEM",
      subcategories: [
        { value: "ITM01", label: "Missing items" },
        { value: "ITM02", label: "Quantity issue" },
        { value: "ITM03", label: "Item mismatch" },
        { value: "ITM04", label: "Quality issue" },
        { value: "ITM05", label: "Expired item" },
        { value: "ITM06", label: "Incorrectly marked as returned" },
      ],
    },
    {
      value: "FLM01",
      label: "FULFILLMENT",
      subcategories: [
        { value: "FLM01", label: "Wrong delivery address" },
        { value: "FLM02", label: "Delay in delivery" },
        { value: "FLM03", label: "Delayed delivery" },
        { value: "FLM04", label: "Packaging" },
        { value: "FLM07", label: "Package info mismatch" },
        { value: "FLM08", label: "Incorrectly marked as delivered" },
      ],
    },
    {
      value: "PMT01",
      label: "PAYMENT",
      subcategories: [
        { value: "PMT01", label: "Refund not received" },
        { value: "PMT02", label: "Underpaid" },
        { value: "PMT04", label: "Not paid" },
      ],
    },
  ];
  const typeOptions = types.map((type, index) => (
    <option key={index} value={type.label}>
      {type.label}
    </option>
  ));
  const selectedType = types.find((item) => item.label === type);
  const subTypeOptions = selectedType
    ? selectedType.subcategories.map((sub, index) => (
      <option key={index} value={sub.value}>
        {sub.label}
      </option>
    ))
    : null;

  const handleItemChange = (itemId) => {
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      selectedItemError: "",
    }));
  };

  return (
    <Modal
      isOpen={props?.RaiseTicket}
      size="md"
      centered
      toggle={() => props?.setRaiseTicket(false)}
      className="RaiseTicketModal"
    >
      <ModalHeader>
        Raise Ticket
        <button className="btn" onClick={() => props?.setRaiseTicket(false)}>
          <img alt="Close" src={crossIcon} />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="RaiseTicketCards">
          <h1>Choose item that had a Problem*</h1>
          <ul>
            {itemData?.map((item, index) => (
              <li key={index}>
                <div className="raise-card-flex">
                  <div className="raise-left">
                    <div
                    >
                      <input
                        id={`item-checkbox-${index}`}
                        type="checkbox"
                        value={item?.id}
                        disabled={item?.issueAlreadyRaised}
                        checked={selectedItems?.includes(item?.id)}
                        onChange={() => handleItemChange(item?.id)}
                      />
                    </div>
                  </div>
                  <div className="raise-product-img">
                    <img
                      alt="productImage"
                      src={item?.productImages?.[0]}
                    />
                  </div>
                  <div className="raise-product-title">
                    <h2>{item?.productName}</h2>
                    <p>QTY: {item?.quantity?.count}</p>
                    <div className="raise-product-rate">
                      ₹ {item?.discountedPrice}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {errors?.selectedItemError && (
            <p className="error">{errors?.selectedItemError}</p>
          )}
        </div>
        <div className="auth-form-container">
          <div className="did-floating-label-content">
            <select className="did-floating-select" onChange={handleTypeChange}>
              <option value="">Select Type</option>
              {typeOptions}
            </select>
            <label className="did-floating-label">Type</label>
            {errors?.typeError && <p className="error">{errors?.typeError}</p>}
          </div>
          <div className="did-floating-label-content">
            <select
              className="did-floating-select"
              onChange={(e) => {
                setSubType(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  subtypeError: "",
                }));
              }}
            >
              <option value="">Select Subtype</option>
              {subTypeOptions}
            </select>
            <label className="did-floating-label">Subtype</label>
            {errors?.subtypeError && (
              <p className="error">{errors?.subtypeError}</p>
            )}
          </div>
        </div>
        <div className="did-floating-label-content">
          <input
            className="did-floating-input"
            type="text"
            placeholder=" "
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, subjectError: "" }));
            }}
          />
          <label className="did-floating-label">Subject</label>
          {errors?.subjectError && (
            <p className="error">{errors?.subjectError}</p>
          )}
        </div>
        <div className="did-floating-label-content">
          <textarea
            className="did-floating-input floating-textarea"
            type="text"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              setErrors((prevErrors) => ({ ...prevErrors, messageError: "" }));
            }}
          ></textarea>
          <label className="did-floating-label">Message </label>
          {errors?.messageError && (
            <p className="error">{errors?.messageError}</p>
          )}
        </div>
        <div className="floation-file-uploader">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            multiple
          />
        </div>
        <div className="add-items">
          {uploadedImageUrl.map((item, index) => {
            return (
              <div className="add-product-item">
                <div className="product-Img">
                  <img src={item} alt="" />
                </div>
                <div
                  className="product-cross"
                  onClick={() => handleCross(index)}
                >
                  <crossIcon />
                </div>
              </div>
            );
          })}
        </div>
        {errors?.uploadedImageUrlError && (
          <p className="error">{errors?.uploadedImageUrlError}</p>
        )}
        <div className="text-center mt-4">
          <button
            className="btn btn-primary btn-md"
            disabled={
              uploadFilestatus === "loading" || raiseIssuestatus === "loading"
            }
            onClick={() => raiseIssue()}
          >
            {
              uploadFilestatus === "loading" ||
                raiseIssuestatus === "loading" ?
                "Please Wait..." :
                "Submit"}
          </button>
          {errors?.commonError && (
            <p className="error">{errors?.commonError}</p>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
export default RaiseIssue;
