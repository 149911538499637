import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../Header/component";
import Clothes from "../../images/ProductImg/clothes.png";
import Electronics from "../../images/ProductImg/electronics.png";
import Food from "../../images/ProductImg/food.png";
import Furniture from "../../images/ProductImg/furniture.png";
import Grocery from "../../images/ProductImg/grocery.png";
import Health from "../../images/ProductImg/health.png";
import PersonalCare from "../../images/ProductImg/personal-care.png";
import { fetchSellers } from "../../slices/sellerSlice";
import { get } from "lodash";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/loader";
import Footer from "../../components/common/Footer";

const ProductDetails = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultAddress = JSON.parse(localStorage.getItem("defaultAddress"));
  useEffect(() => {
    if (defaultAddress) {
      dispatch(
        fetchSellers({
          latitude: defaultAddress?.region?.latitude,
          longitude:defaultAddress?.region?.longitude,
          areaCode:defaultAddress?.zipcode,
        })
      )
    }
  }, [dispatch]);

  const {
    sellers,
    sellersStatus,
  } = useSelector((state) => ({
    sellers: get(state, "sellers.data.sellers", []),
    sellersStatus: get(state, "sellers.sellersStatus", ""),
  }));
  const [addressModal, setAddressModal] = useState(false);
  const [imageLoaded, setImageLoaded] = useState([]);
  const handleSellerClick = (seller) => {
    localStorage.setItem("sellerDetails", JSON.stringify(seller));
    navigate(`/${seller?.providerId}`, {
      state: {
        category:"All"
      },
    });
  };

  const handleCategoryClick = (category) => {
    navigate(`/product-category?category=${encodeURIComponent(category)}`);
  };
 return (
    <>
      <Header addressModal={addressModal} setAddressModal={setAddressModal} />
      <section className="products-block">
        <div className="container">
          <div className="product-container">
          <div className="grid-main">
            <div className="column">
              <div className="sub-col">
                <div
                  className="item col-medium blue-gradient"
                  onClick={() => {
                    handleCategoryClick("Fashion");
                  }}
                >
                  <div className="product--img">
                    <img alt="" src={Clothes} />
                  </div>
                  <h5 className="product-title">Fashion</h5>
                </div>
                <div
                  className="item col-small lightblue-gradient product-card-flex"
                  onClick={() => {
                    handleCategoryClick("Electronics");
                  }}
                >
                  <div className="product--img">
                    <img alt="" src={Electronics} />
                  </div>
                  <h5 className="product-title">Electronics</h5>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="sub-col">
                <div
                  className="item col-small lightPink-gradient product-card-flex"
                  onClick={() => {
                    handleCategoryClick("Grocery");
                  }}
                >
                  <div className="product--img">
                    <img alt="" src={Grocery} />
                  </div>
                  <h5 className="product-title">Grocery</h5>
                </div>
                <div className="item  col-vertical">
                  <div
                    className="item col-sm-vertical orange-gradient"
                    onClick={() => {
                      handleCategoryClick("Food & Beverage");
                    }}
                  >
                    <div className="product--img">
                      <img alt="" src={Food} />
                    </div>
                    <h5 className="product-title">Food & Beverage</h5>
                  </div>
                  <div
                    className="item col-sm-vertical green-gradient "
                    onClick={() => {
                      handleCategoryClick("Health & Wellness");
                    }}
                  >
                    <div className="product--img">
                      <img alt="" src={Health} />
                    </div>
                    <h5 className="product-title">Health & Wellness</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="sub-col">
                <div
                  className="item col-medium cream-gradient "
                  onClick={() => {
                    handleCategoryClick("Home & Kitchen");
                  }}
                >
                  <div className="product--img">
                    <img alt="" src={Furniture} />
                  </div>
                  <h5 className="product-title">Home & Kitchen</h5>
                </div>
                <div
                  className="item col-small purple-gradient product-card-flex"
                  onClick={() => {
                    handleCategoryClick("Beauty & Personal Care");
                  }}
                >
                  <div className="product--img">
                    <img alt="" src={PersonalCare} />
                  </div>
                  <h5 className="product-title">Beauty & Personal Care</h5>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </section>
      <section className="all-providers-conatiner">
        <div className="container">
          <div className="all-providers">
            <h4 className="main_heading">All Providers</h4>
            {sellersStatus !== "loading" ? (
              <div className="page-container">
              <div className="stores-list">
                {sellers.length > 0 ? (
                  sellers.map((seller, index) => {
                    const storeName = get(seller, "name", "Not Available");
                    return (
                      <div
                        className="store-item clickable"
                        onClick={() => {
                          handleSellerClick(seller);
                        }}
                      >
                        <div className="store-img">
                          <img
                          alt=""
                            src={seller?.photo}
                            onLoad={() => {
                              setImageLoaded((prevState) => {
                                const updatedState = [...prevState];
                                updatedState[index] = {
                                  ...updatedState[index],
                                  loaded: true,
                                };
                                return updatedState;
                              });
                            }}
                            onError={(e) => {
                              e.target.style.display = "none";
                              setImageLoaded((prevState) => {
                                const updatedState = [...prevState];
                                updatedState[index] = {
                                  ...updatedState[index],
                                  loaded: false,
                                };
                                return updatedState;
                              });
                            }}
                          />
                          {!imageLoaded[index]?.loaded && (
                            <div>
                              <span>{storeName}</span>
                            </div>
                          )}
                        </div>
                        <div className="store_content">
                          <h6>{storeName}</h6>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No stores found</p>
                )}
              </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default ProductDetails;
