export { default as Cart } from "./cartIcon";
export { default as DownArrow } from "./downArrow";
export { default as Location } from "./location";
export { default as SearchIcon } from "./searchicon";
export { default as RightArrow } from "./right-arrow";
export { default as LeftArrow } from "./leftArrow";
export { default as Plus } from "./plus";
// export { default as Cart } from "./cart";
// export { default as LoadingSpiner } from "./loadinspiner";
export { default as Info } from "./infoIcon";
export { default as Successfully } from "./succesfully";
export { default as Whatsaap } from "./whatsaap";
export { default as Contact } from "./contact";
export { default as Profile } from "./Profile";
export { default as ShoppingCart } from "./ShoppingCart";
export { default as Cartnew } from "./cartnewicon";
export { default as HamburgerNew } from "./Hamburger";
export { default as DeleteIcon } from "./DeleteIcon";
export { default as Phone } from "./Phone";
export { default as CartICon } from "./Cart";
export { default as CrossIcon } from "./Cross-icon";
export { default as PlusIcon } from "./PlusIcon";
export { default as MinusIcon } from "./Minus";
export { default as ProfileUser } from "./ProfileUser";
export { default as DefaultImage } from "./DefaultImage";
export { default as LikeIcon } from "./LikeIcon";
export { default as Pin } from "./Pin";
export { default as StarIcon } from "./StarIcon";
export { default as AddIcon } from "./AddIcon";
export { default as EditIcon } from "./EditIcon";
export { default as Rightarrow } from "./ArrowRight";
