import React, { useEffect, useState } from "react";
import Header from "../Header/component";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoData from "../../components/NewImages/no-data.png"
import { notify } from "../../components/common/utils";
import { ToastContainer } from "react-toastify";
import { updateCartItem} from "../../slices/catalogSlice";
import Loader from "../../components/common/loader";
import { MinusIcon, PlusIcon } from "../../components/svgicons";
import BrandLogo from "../../images/kikoimg.svg";

const Search = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let cartItems = [];
    const [catalogDataTemp, setCatalogDataTemp] = useState([]);
    const searchedcatalogues = useSelector((s) => s.catalog.searchedcatalogues);
    const searchCatalogueStatus = useSelector((s) => s.catalog.searchCatalogueStatus);
    const cartItems2 = useSelector((s) => s.catalog.cartItems);
    cartItems = JSON.parse(localStorage.getItem("cart")) || [];

    useEffect(() => {
        catalogs();
      }, [cartItems2]);

      useEffect(() => {
        if (searchedcatalogues) {
            catalogs();
        }
    }, [searchedcatalogues]);

    const updateCartItemQuantity = (itemId, newQuantity, singleCatalogData) => {
        const itemIndex = cartItems.findIndex((item) => item._id === itemId);
        if (newQuantity > parseInt(singleCatalogData?.availableQuantity)) {
          notify("success","Available Quantity is only " + singleCatalogData.availableQuantity)
        } else {
          let updatedCartItems = [...cartItems];
          if (itemIndex !== -1) {
            if (newQuantity === 0) {
              updatedCartItems = updatedCartItems.filter((item) => item._id !== itemId); 
            } else {
              updatedCartItems[itemIndex].quantity = newQuantity;
            }
          } else {
            if (newQuantity !== 0) {
            const newCartItem = {
              _id: singleCatalogData._id,
              quantity: newQuantity,
              catalogData: singleCatalogData,
            };
            updatedCartItems = [...cartItems, newCartItem];
          } else {
            updatedCartItems = [...cartItems];
          }
          }
          localStorage.setItem("cart", JSON.stringify(updatedCartItems));
          dispatch(updateCartItem(updatedCartItems));
        }
      };

    const catalogs = () => {
        if (!searchedcatalogues) {
            return;
        }
        const data = searchedcatalogues?.map((data) => {
            const index = cartItems?.findIndex((item) => item._id === data._id);
            if (index !== -1) {
                return { ...data, quantity: cartItems[index].quantity };
            } else {
                return { ...data, quantity: 1 };
            }
        });
        setCatalogDataTemp([...data]);
    };
    
    return (
        <>
        <ToastContainer
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
            <Header />
            <div>
           {searchCatalogueStatus === "loaded" && catalogDataTemp && catalogDataTemp.length > 0 ? (
            <div className="searchComponent">
            <div className="categoryCardsContainer w-100">
                       <div className="stores-list mt-0">
                        {catalogDataTemp?.map((data, index) => (
                            <div key={index} className="store-item clickable">
                                <div
                                    className="store-img"
                                    onClick={() =>
                                        navigate("/productDetails", {
                                            state: {
                                                catalogData: data,
                                                catalogDataArray: catalogDataTemp,
                                            },
                                        })
                                    }
                                >
                                    {data?.productImages && data?.productImages.length > 0 && (
                                        <img src={data?.productImages?.[0]} alt={data?.productName} 
                                        onError={(event) => {
                                          event.target.onerror = null; 
                                          event.target.src = BrandLogo; 
                                          event.target.classList.add('default-img')
                                        }}
                                        />
                                    )}
                                </div>
                                <div className="store-content">
                              <h6> {data?.productName}</h6>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>
                                  <span className="purpleText">
                                    ₹{data?.price}
                                  </span>
                                </p>
                                <div className="addtoCart">
                                  <div className="add--cart-btn">
                                    <button
                                      id={data._id}
                                      className="addBtn"
                                      onClick={() => {
                                        updateCartItemQuantity(
                                          data._id,
                                          data.quantity,
                                          data
                                        );
                                      }}
                                    >
                                      Add
                                    </button>
                                    <div
                                      style={{ visibility: (cartItems.some(item => item?._id === data?._id) ? "visible" : "hidden") }}
                                      id={`ui${data._id}`}
                                      className="addtocart-qty"
                                    >
                                      <div
                                        className="addtocart-button button-down minus"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            data.quantity <= 1
                                              ? 0
                                              : (data.quantity -= 1),
                                            data
                                          );
                                        }}
                                      >
                                      <MinusIcon/>
                                      </div>
                                      <input
                                        type="number"
                                        className="addtocart-input"
                                        value={data.quantity}
                                      />
                                      <div
                                        className="addtocart-button button-up"
                                        onClick={() => {
                                          updateCartItemQuantity(
                                            data._id,
                                            (data.quantity += 1),
                                            data
                                          );
                                        }}
                                      >
                                       <PlusIcon/>
                                       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                        ))}
                    </div>
            </div>
            </div>
              ) : (
                searchCatalogueStatus === "loading" ? 
                <p className="search-loader"><Loader/></p>:
                (searchCatalogueStatus !== "loading" && searchCatalogueStatus !== "loaded") ?
                <div className="nothing-match">
                  <p></p>
                </div> :
                <div className="nothing-match">
                 <div className="text-center">
                 <img src={NoData} alt="oops not found" style={{maxWidth:"180px"}}/>
                 </div>
                <p>Nothing here yet</p>
             </div>
            )}
            </div>
        </>
    );
};

export default Search;