export const GET_HOME_CATALOG = "/kikoweb/catalogues-group-by-categories";
export const GET_CATEGORIES = "/get-catalogue-categories";
export const GET_SELLER_CATALOG = "/get-catalogue";
export const GET_USER_ADDRESS = "/get-address";
export const ADD_USER_ADDRESS = "/create-address";
export const ESTIMATION = "/order/delivery-estimation";
export const REQUEST_OTP = "/request-otp-v2";
export const STORE_DETAILS = "/user/fetch-store-details";
export const LOGIN = "/login";
export const GET_SELLER_DETAIL = "/accounts/get-user-by-slug/";
export const CREATE_ORDER_OFFLINE = "/order/create-order-offline-v2";
export const GET_PINCODE_DETAILS = "/maps/getpicodedetails";
export const SEARCH_CATALOG = "/fetch-Catalogues"
export const UPDATE_USER_PROFILE = "/accounts/edit"
export const GET_BUYER_ORDER = "/get-buyer-order"
export const GET_ORDER_DETAILS = "/order/"
export const GET_SELLERS = "/get-sellers"
export const ONDC_SELECT = "/select"
export const ONDC_CART = "/cart"
export const ONDC_INIT = "/init"
export const ONDC_CONFIRM = "/confirm"
export const ONDC_ORDER_STATUS = "/status"
export const GET_ORDER_DETAIL = "/get-order-detail"
export const ONDC_CANCEL_ORDER = "/cancel"
export const ONDC_RETURN_ORDER = "/return"
export const UPLOAD_FILES = "/upload"
export const RAISE_ISSUE = "/issues"
export const ONDC_ISSUE_STATUS = "/issueStatus"
export const ONDC_ISSUE_COMPLETE = "/issueComplete"
export const ONDC_ISSUE_ESCALATE = "/issueEscalate"
export const ONDC_ORDER_TRACK = "/track"