import React, { useState, useRef } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./styles.scss";
import crossIcon from "../../images/cross-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { get } from "lodash";
import { ondcRetun, uploadFile } from "../../slices/ondcPostorderSlice";
import { notify } from "../../components/common/utils";
import { MinusIcon, PlusIcon } from "../../components/svgicons"

const ReturnOrder = (props) => {
  const orderId = props?.orderId
  const itemData = props?.itemData;
  const dispatch = useDispatch();
  const [returnReason, setReturnReason] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState({});
  const fileInputRef = useRef(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [errors, setErrors] = useState({
    selectedItemError: "",
    returnReasonError: "",
    fileUploadError: "",
    commonError: "",
  });
  const { uploadFilestatus, ondcRetunstatus } = useSelector((state) => ({
    uploadFilestatus: get(state, "ondcPostorder.uploadFilestatus", ""),
    ondcRetunstatus: get(state, "ondcPostorder.ondcRetunstatus", ""),
  }));
  const removeImage = (data) => {
    setUploadedImages((prevState) =>
      prevState.filter((image, index) => index !== parseInt(data))
    );
  };

  const returnOrder = async () => {
    try {
      if (
        !returnReason ||
        !uploadedImages ||
        uploadedImages.length === 0 ||
        !selectedItem
      ) {
        if (!selectedItem) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            selectedItemError: "Please Select a product",
          }));
        }
        if (!returnReason || Object.keys(returnReason).length === 0) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            returnReasonError: "Please select a reason for return.",
          }));
        }
        if (!uploadedImages || uploadedImages.length === 0) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fileUploadError: "Please Upload Image",
          }));
        }
      } else {
        dispatch(
          ondcRetun({
            orderId: orderId,
            returnReasonCode: returnReason?.value,
            returnReason: returnReason?.label,
            productImages: uploadedImages,
            itemId: selectedItem,
            quantity: selectedQuantity[selectedItem] || 1,
          })
        ).then((data) => {
          if (data?.payload?.success) {
            props.setReturnModal(false);
            props.getOrderDetail()
            notify("success", "Return Request Submitted !");
          } else {
            notify(
              "error",
              "Somthing Went Wrong Please Try Again After Some Time !"
            );
            setErrors((prevErrors) => ({
              ...prevErrors,
              commonError:
                "Somthing Went Wrong Please Try Again After Some Time !",
            }));
          }
        });
      }
    } catch (error) {
      console.error("Error returning order", error);
    }
  };

  const handleQuantityChange = (itemId, maxQuantity, action) => {
    const currentValue = selectedQuantity[itemId] || maxQuantity;
    let newValue;
    if (action === "increase") {
      newValue = currentValue < maxQuantity ? currentValue + 1 : currentValue;
    } else if (action === "decrease") {
      newValue = currentValue > 1 ? currentValue - 1 : currentValue;
    }
    setSelectedQuantity({
      ...selectedQuantity,
      [itemId]: newValue,
    });
  };

  const handleFileUpload = async (e) => {
    const formData = new FormData();
    const file = e.target.files[0];
    formData.append(`file`, file);
    try {
      dispatch(uploadFile(formData)).then((data) => {
        if (data?.payload && data?.payload?.success) {
          setUploadedImages((prevProductImage) => [
            ...prevProductImage,
            data?.payload?.file_url,
          ]);
          setErrors((prevErrors) => ({ ...prevErrors, fileUploadError: "" }));
          notify("success", "Image uploaded successfully!");
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            fileUploadError:
              "Something went wrong in Uploading Image. Please try again.",
          }));
        }
        fileInputRef.current.value = "";
      });
    } catch (error) {
      console.error("Error uploading image:", error);
      setErrors((prevErrors) => ({
        ...prevErrors,
        fileUploadError: "Failed to upload image.",
      }));
    }
  };

  const reasons = [
    { value: "", label: "Select reason for return" },
    { value: "001", label: "Does not want product any more" },
    { value: "002", label: "Product available at lower than order price" },
    { value: "003", label: "Product damaged or not in usable state" },
    { value: "004",label: "Product is of incorrect quantity or size"},
    { value: "005",label: "Product delivered is different from what was shown and ordered"},
  ];
  
  return (
    <Modal
      isOpen={props?.returnModal}
      size="md"
      centered
      toggle={() => props?.setReturnModal(false)}
      className="RaiseTicketModal"
    >
      <ModalHeader>
        Return Order
        <button
          className="btn"
          onClick={() => {
            props?.setReturnModal(false);
          }}
        >
          <img alt="Close" src={crossIcon} />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="RaiseTicketCards">
          <ul>
            {itemData
              .map((item, index) => {
                const currentValue =
                  selectedQuantity[item?.id] || item?.quantity?.count;
                const givenDateTime = new Date(item?.returnWindowTime);
                const currentDateTime = new Date();
                const isReturnWindowExpired = givenDateTime < currentDateTime
                return (
                  <li key={index}>
                    <div className="raise-card-flex">
                      <div className="raise-left">
                        <div>
                          <input
                            id="wp-comment-cookies-consent"
                            name="wp-comment-cookies-consent"
                            type="checkbox"
                            value={selectedItem}
                            disabled={!item?.isReturnable || isReturnWindowExpired}
                            checked={selectedItem === item?.id}
                            onChange={() => {
                              setSelectedItem(item?.id);
                              setSelectedQuantity({
                                ...selectedQuantity,
                                [item?.id]: currentValue,
                              });
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                selectedItemError: "",
                              }));
                            }}
                          />
                        </div>
                        <div className="raise-product-img">
                          <img
                            alt="productImage"
                            src={item?.productImages?.[0]}
                          />
                        </div>
                        <div className="raise-product-title">
                          <h2>{item?.productName} </h2>
                          <div className="return-cancle-flex">
                            <span className="defaultProductPrice">
                              {!item?.isReturnable ? "Non-Returnable" : isReturnWindowExpired ? "Return Window Expired" : ""}
                            </span>
                          </div>
                          <p>QTY: {item.quantity.count}</p>
                          <div className="raise-product-rate">
                            ₹ {item?.discountedPrice}
                          </div>
                        </div>
                      </div>
                      {(item?.isReturnable && !isReturnWindowExpired) && (
                        <div className="raise-right">
                          <div className="addtocart-selector">
                            <div className="addtocart-qty">
                              <div
                                className="addtocart-button button-down minus"
                                onClick={() =>
                                  handleQuantityChange(
                                    item?.id,
                                    item?.quantity?.count,
                                    "decrease"
                                  )
                                }
                              >
                                <MinusIcon />
                              </div>
                              <input
                                type="text"
                                className="addtocart-input"
                                value={currentValue}
                              />
                              <div
                                className="addtocart-button button-up"
                                onClick={() =>
                                  handleQuantityChange(
                                    item?.id,
                                    item?.quantity?.count,
                                    "increase"
                                  )
                                }
                              >
                                <PlusIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>
          {errors?.selectedItemError && (
            <p className="error">{errors?.selectedItemError}</p>
          )}
          <div className="reurn-order-modal">
            <div className="did-floating-label-content">
              <select
                className="did-floating-select"
                value={JSON.stringify(returnReason)}
                onChange={(e) => {
                  const selectedReason = JSON.parse(e.target.value);
                  setReturnReason(selectedReason);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    returnReasonError: "",
                  }));
                }}
              >
                {reasons.map((reason) => (
                  <option key={reason?.value} value={JSON.stringify(reason)}>
                    {reason?.label}
                  </option>
                ))}
              </select>
              <label className="did-floating-label">Select Reason*</label>
            </div>
            {errors?.returnReasonError && (
              <p className="error">{errors.returnReasonError}</p>
            )}
            <div className="floation-file-uploader">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
            </div>
            <div className="add-items">
              {uploadedImages.map((item, index) => {
                return (
                  <div className="add-product-item">
                    <div className="product-Img">
                      <img src={item} alt="" />
                    </div>
                    <div
                      className="product-cross"
                      onClick={() => removeImage(index)}
                    >
                      <crossIcon />
                    </div>
                  </div>
                );
              })}
            </div>

            {errors?.fileUploadError && (
              <p className="error">{errors?.fileUploadError}</p>
            )}
          </div>
        </div>
        {errors?.commonError && (
          <p className="error">{errors?.commonError}</p>
        )}
        <div className="text-center mt-4">
          <button
            disabled={
              uploadFilestatus === "loading" || ondcRetunstatus === "loading"
            }
            onClick={returnOrder}
            className="btn btn-primary btn-md"
          >
            {uploadFilestatus === "loading" || ondcRetunstatus === "loading"
              ? "Please Wait..."
              : "Submit"}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ReturnOrder;
