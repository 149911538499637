
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSellersCatalogData , fetchCateloguesData} from "../api/ApiService";

export const initialState = {
  loading: false,
  hasErrors: false,
  status: false,
  data: [],
  catalogues: [],
  searchedcatalogues: [],
  catalogueStatus: "loading",
  searchCatalogueStatus:"",
  catalogueCount:0,
  cartItems: JSON.parse(localStorage.getItem("cart")) || [],
};

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.loading = action.payload;
    },
    updateCartItem: (state,action) => {
      state.cartItems = action?.payload || []; 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSellersCatalog.pending, (state) => {
        state.hasErrors = false;
        state.catalogueStatus = "loading"
      })
      .addCase(fetchSellersCatalog.fulfilled, (state, action) => {
        state.catalogues = action?.payload?.data?.results || [];
        state.catalogueStatus = "loaded";
        state.catalogueCount =  action?.payload?.data?.catalogueCount || 0;
      })
      .addCase(fetchSellersCatalog.rejected, (state) => {
        state.hasErrors = true;
        state.catalogues = []
        state.catalogueStatus = "error"
      })
      .addCase(fetchCatelogues.pending, (state) => {
        state.hasErrors = false;
        state.searchCatalogueStatus = "loading"
      })
      .addCase(fetchCatelogues.fulfilled, (state, action) => {
        state.searchedcatalogues = action?.payload?.data;
        state.searchCatalogueStatus = "loaded"

      })
      .addCase(fetchCatelogues.rejected, (state) => {
        state.hasErrors = true;
        state.searchCatalogueStatus = " error"
      });
  },
});

export const fetchSellersCatalog = createAsyncThunk(
  'seller/fetchSellersCatalog',
  async (body) => {
    const data = await fetchSellersCatalogData(body);
    return data

  }
)

export const fetchCatelogues = createAsyncThunk(
    'seller/fetchCatelogues',
    async (body) => {
      const data = await fetchCateloguesData(body);
      return data

    }
  );

export const { startLoading, getCatalogSuccess, getCatalogFailure ,updateCartItem} = catalogSlice.actions;

export default catalogSlice.reducer;

