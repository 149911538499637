import React from "react";
const Icon = (props) =>  (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      fill="#000"
      d="M10.664 5.33h-4v-4a.667.667 0 0 0-1.333 0v4h-4a.667.667 0 0 0 0 1.334h4v4a.667.667 0 0 0 1.333 0v-4h4a.667.667 0 0 0 0-1.333"
    ></path>
  </svg>
  );


export default Icon;
